import React from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Main from "./Components/Main";

import "./App.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons";
import {
  faHardHat,
  faClipboardCheck,
  faMapMarked,
  faHandsHelping,
  faHeartbeat,
  faChalkboardTeacher,
  faPaperPlane,
  faMobile,
  faPhone,
  faAt
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faHardHat,
  faClipboardCheck,
  faMapMarked,
  faHandsHelping,
  faHeartbeat,
  faChalkboardTeacher,
  faPaperPlane,
  faMobile,
  faPhone,
  faFacebookF,
  faTwitter,
  faAt
);

function App() {
  return (
    <div className="App">
      <section className="App-header">
        <Header />
        <Main />
        <Footer />
      </section>
    </div>
  );
}

export default App;
