import React from "react";
import Obfuscate from "react-obfuscate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Footer.css";

const Footer = props => (
  <div className="FooterWrapper">
    <div className="column-1">
      <div>
        <FontAwesomeIcon icon={["fab", "facebook-f"]} pull="left" />
        <a href="https://www.facebook.com/clconsulting84">Facebook</a>
        <FontAwesomeIcon icon={["fab", "facebook-f"]} pull="right" />
      </div>
      <div>
        <FontAwesomeIcon icon={["fab", "twitter"]} pull="left" />
        <a href="https://twitter.com/consulting_cl">Twitter</a>
        <FontAwesomeIcon icon={["fab", "twitter"]} pull="right" />
      </div>
    </div>
    <div className="column-2">
      <div>
        <FontAwesomeIcon icon="mobile" pull="left" />
        Portable: <Obfuscate tel="07.81.30.89.05" />
        <FontAwesomeIcon icon="mobile" pull="right" />
      </div>
      <div>
        <FontAwesomeIcon icon="at" pull="left" />
        Courriel <Obfuscate email="legros.celine@clconsulting.fr" />
        <FontAwesomeIcon icon="at" pull="right" />
      </div>
    </div>
  </div>
);

export default Footer;
