import React from "react";
import Logo from "./Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const logoStyle = {
  height: "calc(35px + 10vmin)"
};

const Header = props => (
  <div className="HeaderWrapper">
    <img src={Logo} alt="Logo" style={logoStyle} />
    <p>
      <FontAwesomeIcon icon="hard-hat" size="3x" color="#EED202" />
    </p>
    <p>Site en travaux !</p>
  </div>
);

export default Header;
