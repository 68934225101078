import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Main.css";

const Main = props => (
  <div className="MainWrapper">
    <p>
      Bienvenue sur le site de CL Consulting, bureau d'études et de conseil.
    </p>
    <p>
      <FontAwesomeIcon icon="clipboard-check" pull="left" />
      Evaluation et pilotage des politiques publiques
      <FontAwesomeIcon icon="clipboard-check" pull="right" />
    </p>
    <p>
      <FontAwesomeIcon icon="map-marked" pull="left" />
      Stratégie territoriale et projets de territoires
      <FontAwesomeIcon icon="map-marked" pull="right" />
    </p>
    <p>
      <FontAwesomeIcon icon="hands-helping" pull="left" />
      Analyses des besoins sociaux
      <FontAwesomeIcon icon="hands-helping" pull="right" />
    </p>
    <p>
      <FontAwesomeIcon icon="heartbeat" pull="left" />
      Diagnostics de territoires
      <FontAwesomeIcon icon="heartbeat" pull="right" />
    </p>
    <p>
      <FontAwesomeIcon icon="chalkboard-teacher" pull="left" />
      Ateliers et formations
      <FontAwesomeIcon icon="chalkboard-teacher" pull="right" />
    </p>
    <p>
      <FontAwesomeIcon icon="paper-plane" pull="left" />
      Logiciel de pilotage de l'action publique
      <FontAwesomeIcon icon="paper-plane" pull="right" />
    </p>
  </div>
);

export default Main;
